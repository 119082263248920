<template>
  <div>
      <div class="text-center mb-2">
          <el-radio-group v-model="field.is_global_variable" >
              <el-radio-button :label="false">Custom Field</el-radio-button>
              <el-radio-button :label="true">Global Variable</el-radio-button>
          </el-radio-group>
      </div>
      <el-form label-position="right" v-if="!field.is_global_variable">
          <title-and-description :field="field" />
          <el-row type="flex" :gutter="30">
              <el-col :span="12">
              <field-filled-by :field="field" />
              </el-col>
          </el-row>
          <el-row type="flex" :gutter="30">
               <el-col :span="12">
                <el-switch
                  v-model="field.validations.mapViewRequired"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Enable in MapView">
                 </el-switch>
                 <el-switch
                  v-model="field.validations.AllowEdit"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="AllowEdit"
                  style="margin-left: 10px;">
                 </el-switch>
               </el-col>
              <el-col :span="12">
        <is-field-required :field="field" class="field-required"/>
        </el-col>
        
          </el-row>
      </el-form>
      <el-form label-position="right" v-if="field.is_global_variable">
          <el-row type="flex" :gutter="30">
              <el-col :span="12">
              <div class="form-group">
             
                  <el-form-item label="Global Variable">
                  <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                      <el-option
                      v-for="(globalVariable,index) of allGlobalVariables"
                      :key="index"
                      :label="globalVariable.label"
                      filterable
                      :value="globalVariable._id"
                      ></el-option>
                  </el-select>
                  </el-form-item>
              </div>
              </el-col>
          </el-row>
          <el-row type="flex" :gutter="30">
              <el-col :span="12">
              <field-filled-by :field="field" />
              </el-col>
          </el-row>
          <is-field-required :field="field" class="field-required"/>
      </el-form>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
//  import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import FieldAttributes from "./FieldAttributes";
// import Placeholder from "./Placeholder";
import { mapGetters } from "vuex";
export default {
name:"templates-formComponents-Location",
components: {
   "TitleAndDescription":()=>import("./TitleAndDescription"),
  "IsFieldRequired":()=>import("./IsFieldRequired"),
  "FieldFilledBy":()=>import("./FieldFilledBy"),
  // "Placeholder":()=>import("./Placeholder"),
},

props: ["field"],
computed: {
  ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
  allGlobalVariables() {
    console.log("getAllGlobalVariables",this.getAllGlobalVariables);
    return this.getAllGlobalVariables
      ? this.getAllGlobalVariables.data || []
      : [];
  }
},
mounted() {
  this.fetchGlobalVaribales();
},
data() {
  return {

  };
},
methods: {
  parseCoordinates() {
      // Example Google Maps URL: https://www.google.com/maps/place/37.7749,-122.4194
      const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
      const match = this.googleMapsUrl.match(regex);

      if (match) {
        const latitude = parseFloat(match[1]);
        const longitude = parseFloat(match[2]);
        this.center = [latitude, longitude];
        this.coordinates = [latitude, longitude];
       // Optionally, you can set the zoom level based on your requirements
        this.zoom = 15;
       // Optionally, pan the map to the new coordinates
        this.$refs.map.panTo([latitude, longitude]);
      }},
  async fetchGlobalVaribales() {
   
    let params = {
      get_all: true,
      input_type: this.field.input_type
    };

    await this.$store.dispatch(
      "globalVariables/fetchGlobalVariables",
      params
    );
  },
  setGlobalVariable() {
    let globalVariable = this.allGlobalVariables.find(
      x => x._id == this.field.global_variable_id
    );
    this.field.label = globalVariable.label;
    this.field.description = globalVariable.description;
    this.field.options = globalVariable.options;
  }
}
};
</script>

<style lang="scss" scoped>
.map{
  display: flex;
    flex-direction: row;
    justify-content: space-around;
}
</style>